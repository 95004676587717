<template>
  <div>
    <div class="DivBox">
      <p class="PTitle">APQP项目管理软件</p>
    </div>
    <div class="DivBox">
      <p class="PTitle LeftBor">服务介绍：</p>
      <br />
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/70.png"
        class="SonImgW"
      />
      <br />
      <br />
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/71.png"
        class="SonImgW"
      />
      <br />
      <br />
      <br />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openWindow() {
      window.open("https://www.yuntixi.cn", "_blank");
    },
  },
};
</script>

<style>
@import "../../../../assets/style/FirstProductSon.css";
</style>